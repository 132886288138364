import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { creteView } from '../../Redux/BoardReducer';

const OrderModal = () => {
	return (
		<div className='modal'>
			<h1>FILTER</h1>
		</div>
	);
};

export default OrderModal;
